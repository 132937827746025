import {Bento, Box, Grid, Heading, Link, River, RiverBreakout, SectionIntro, Text, Timeline} from '@primer/react-brand'
import {useEffect, useRef, type VideoHTMLAttributes} from 'react'
import {DeviceMobileIcon} from '@primer/octicons-react'
import {analyticsEvent} from '../../../../lib/analytics'
import {Image} from '../../../../components/Image/Image'

import FeaturesBreakoutLgVideo from '../_assets/features-breakout.mp4'
import FeaturesBreakoutLgPoster from '../_assets/features-breakout-poster.webp'
import FeaturesBreakoutSmVideo from '../_assets/features-breakout-sm.mp4'
import FeaturesBreakoutSmPoster from '../_assets/features-breakout-poster-sm.webp'
import FeaturesBreakoutImage from '../_assets/features-breakout.webp'
import FeaturesRiver1Image from '../_assets/features-river-1.webp'
import FeaturesRiver1LgVideo from '../_assets/features-river-1.mp4'
import FeaturesRiver1SmVideo from '../_assets/features-river-1-sm.mp4'
import FeaturesRiver1LgPoster from '../_assets/features-river-1-poster.webp'
import FeaturesRiver1SmPoster from '../_assets/features-river-1-poster-sm.webp'

import CopilotMobileImage from '../_assets/copilot-mobile.webp'
import CopilotTerminalLgImage from '../_assets/copilot-terminal.webp'
import CopilotTerminalSmImage from '../_assets/copilot-terminal-sm.webp'
import CopilotModelsImage from '../_assets/copilot-models.webp'
import CopilotFixImage from '../_assets/copilot-fix.webp'

import AppStoreImage from '../_assets/app-store.png'
import GooglePlayImage from '../_assets/google-play.png'

// Auto-play videos
//
// Starts playing videos when they're in view

interface VideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
  src: string
  poster: string
}

function useAutoplayVideo() {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    const currentVideoRef = videoRef.current

    const observer = new IntersectionObserver(
      entries => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            // eslint-disable-next-line github/no-then
            if (currentVideoRef) currentVideoRef.play().catch(() => {})
          } else {
            if (currentVideoRef) currentVideoRef.pause()
          }
        }
      },
      {
        threshold: 0.5, // Play once 50% of the height is...
        rootMargin: `-25% 0% -25% 0%`, // ...in the center 50% of the viewport
      },
    )

    if (currentVideoRef) {
      observer.observe(currentVideoRef)
    }

    return () => {
      if (currentVideoRef) {
        observer.unobserve(currentVideoRef)
      }
    }
  }, [])

  return videoRef
}

function AutoPlayVideo({src, poster, ...props}: VideoProps) {
  const videoRef = useAutoplayVideo()

  return (
    <video ref={videoRef} playsInline muted preload="none" poster={poster} {...props}>
      <source src={src} type="video/mp4; codecs=avc1.4d002a" />
    </video>
  )
}

export default function FeaturesSection() {
  return (
    <section
      id="features"
      className="lp-Section lp-Section--compact lp-SectionIntro--regular lp-Section--noBottomPadding"
      style={{position: 'relative', zIndex: 1, background: 'linear-gradient(180deg, #161B22 0%, #0D1117 25%)'}}
    >
      <Grid className="lp-Section-container--centerUntilMedium lp-Grid--noRowGap">
        <Grid.Column span={12}>
          <SectionIntro fullWidth align="center" className="lp-SectionIntro">
            <SectionIntro.Label size="large" className="lp-Label--section">
              Features
            </SectionIntro.Label>
            <SectionIntro.Heading size="2" weight="semibold">
              Customizable. Contextual. <br className="break-whenWide" /> AI-powerful.
            </SectionIntro.Heading>
          </SectionIntro>
        </Grid.Column>

        <Grid.Column span={12}>
          <RiverBreakout className="lp-RiverBreakout">
            <a
              href="https://docs-gh.yrsoho.top/en/copilot/github-copilot-chat/about-github-copilot-chat#use-cases-for-github-copilot-chat"
              aria-label="Accelerate workflow with interactive codebase chat"
              {...analyticsEvent({
                action: 'chat_docs',
                tag: 'video',
                context: 'copilot_chat',
                location: 'features',
              })}
            >
              <RiverBreakout.Visual aria-hidden="true" className="lp-River-visual">
                <AutoPlayVideo
                  src={FeaturesBreakoutLgVideo}
                  poster={FeaturesBreakoutLgPoster}
                  width="1248"
                  height="647"
                  className="d-none d-md-block hide-reduced-motion"
                  aria-label="Video demonstrating how GitHub Copilot accelerates workflow through interactive codebase chat"
                />
                <AutoPlayVideo
                  src={FeaturesBreakoutSmVideo}
                  poster={FeaturesBreakoutSmPoster}
                  width="350"
                  height="380"
                  className="d-block d-md-none hide-reduced-motion"
                  aria-label="Video demonstrating how GitHub Copilot accelerates workflow through interactive codebase chat"
                />
                <Image
                  src={FeaturesBreakoutImage}
                  alt="Screenshot showing how GitHub Copilot accelerates workflow through interactive codebase chat"
                  width="1248"
                  height="647"
                  style={{display: 'block'}}
                  className="hide-no-pref-motion"
                />
              </RiverBreakout.Visual>
            </a>
            <RiverBreakout.Content
              trailingComponent={() => (
                <Timeline className="lp-Timeline">
                  <Timeline.Item>
                    <em>Improve code quality and security.</em> Developers write{' '}
                    <a
                      className="lp-Link--inline"
                      href="https://github.blog/news-insights/research/does-github-copilot-improve-code-quality-heres-what-the-data-says/"
                      {...analyticsEvent({
                        action: 'code_quality_blog',
                        tag: 'link',
                        context: 'copilot_chat',
                        location: 'features',
                      })}
                    >
                      more functional and higher quality code
                    </a>{' '}
                    with GitHub Copilot. And with the built-in{' '}
                    <a
                      className="lp-Link--inline"
                      href="https://github.blog/2023-02-14-github-copilot-now-has-a-better-ai-model-and-new-capabilities/#filtering-out-security-vulnerabilities-with-a-new-ai-system"
                      {...analyticsEvent({
                        action: 'vulnerability_blog',
                        tag: 'link',
                        context: 'copilot_chat',
                        location: 'features',
                      })}
                    >
                      vulnerability prevention system
                    </a>
                    , insecure coding patterns get blocked in real time.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Enable greater collaboration.</em> GitHub Copilot’s the newest member of your team. Ask it
                    anything—from general programming questions to specific codebase challenges—and get answers fast.
                  </Timeline.Item>
                </Timeline>
              )}
            >
              <Text className="lp-RiverBreakout-text">
                <em>Start a conversation about your codebase.</em> Whether you’re hunting down a bug or designing a new
                feature, turn to GitHub Copilot when you’re stuck.
                <span style={{marginTop: '32px', marginBottom: '48px', display: 'block'}}>
                  <Link
                    href="https://docs-gh.yrsoho.top/en/copilot/example-prompts-for-github-copilot-chat"
                    variant="accent"
                    {...analyticsEvent({
                      action: 'chat_docs',
                      tag: 'link',
                      context: 'copilot_chat_examples',
                      location: 'features',
                    })}
                  >
                    Explore popular prompts
                  </Link>
                </span>
              </Text>
            </RiverBreakout.Content>
          </RiverBreakout>

          <River imageTextRatio="50:50" className="lp-River-mod">
            <River.Visual aria-hidden="true" className="lp-River-visual">
              <a
                href="https://docs-gh.yrsoho.top/en/copilot/using-github-copilot/using-claude-sonnet-in-github-copilot"
                tabIndex={-1}
                aria-hidden="true"
                {...analyticsEvent({
                  action: 'docs_instructions',
                  tag: 'image',
                  context: 'using_sonnet',
                  location: 'features',
                })}
              >
                <Image src={CopilotModelsImage} alt="GitHub Copilot model choices" width="708" height="472" />
              </a>
            </River.Visual>
            <River.Content>
              <Heading as="h3" size="5">
                Match the model to the task
              </Heading>
              <Text as="p" variant="muted" className="lp-River-text">
                <a
                  className="lp-Link--inline"
                  href="https://docs-gh.yrsoho.top/en/copilot/using-github-copilot/using-claude-sonnet-in-github-copilot"
                  {...analyticsEvent({
                    action: 'docs_instructions',
                    tag: 'inline_link',
                    context: 'using_sonnet',
                    location: 'features',
                  })}
                >
                  Claude 3.5 Sonnet
                </a>{' '}
                excels at coding tasks, while OpenAI o1’s capabilities allow for a deeper understanding of code
                constraints and edge cases.
              </Text>
            </River.Content>
          </River>

          <River imageTextRatio="50:50" className="lp-River-mod">
            <River.Visual aria-hidden="true" className="lp-River-visual">
              <a
                href="https://docs-gh.yrsoho.top/en/copilot/using-github-copilot/asking-github-copilot-questions-in-your-ide#slash-commands"
                tabIndex={-1}
                aria-hidden="true"
                {...analyticsEvent({
                  action: 'docs_instructions',
                  tag: 'image',
                  context: 'slash_commands',
                  location: 'features',
                })}
              >
                <Image src={CopilotFixImage} alt="GitHub Copilot performing a code fix" width="708" height="472" />
              </a>
            </River.Visual>
            <River.Content>
              <Heading as="h3" size="5">
                Solve problems with prompts
              </Heading>
              <Text as="p" variant="muted" className="lp-River-text">
                From debugging code to writing unit tests, GitHub Copilot’s slash commands help you use prompts to
                accelerate your work.
              </Text>
              <Link
                href="https://docs-gh.yrsoho.top/en/copilot/using-github-copilot/asking-github-copilot-questions-in-your-ide#slash-commands"
                variant="accent"
                {...analyticsEvent({
                  action: 'docs_instructions',
                  tag: 'link',
                  context: 'slash_commands',
                  location: 'features',
                })}
              >
                Get early access to code review
              </Link>
            </River.Content>
          </River>

          <River imageTextRatio="50:50" className="lp-River-mod">
            <River.Visual aria-hidden="true" className="lp-River-visual">
              <a
                href="https://docs-gh.yrsoho.top/en/copilot/quickstart#introduction"
                tabIndex={-1}
                aria-hidden="true"
                {...analyticsEvent({
                  action: 'quickstart_docs',
                  tag: 'video',
                  context: 'ai_suggestions',
                  location: 'features',
                })}
              >
                <AutoPlayVideo
                  src={FeaturesRiver1LgVideo}
                  poster={FeaturesRiver1LgPoster}
                  width="708"
                  height="472"
                  className="d-none d-md-block hide-reduced-motion"
                  aria-label="Video showing editor with GitHub Copilot code suggestions in real time"
                />
                <AutoPlayVideo
                  src={FeaturesRiver1SmVideo}
                  poster={FeaturesRiver1SmPoster}
                  width="350"
                  height="290"
                  className="d-block d-md-none hide-reduced-motion"
                  aria-label="Video showing editor with GitHub Copilot code suggestions in real time"
                />
                <Image
                  src={FeaturesRiver1Image}
                  alt="Editor with GitHub Copilot code suggestions in real time"
                  width="708"
                  height="472"
                  className="hide-no-pref-motion"
                />
              </a>
            </River.Visual>
            <River.Content>
              <Heading as="h3" size="5">
                Get AI-based suggestions in real time
              </Heading>
              <Text as="p" variant="muted" className="lp-River-text">
                GitHub Copilot suggests code completions as you type and turns natural language prompts into coding
                suggestions based on your project&apos;s conventions.
              </Text>
              <Link
                href="https://docs-gh.yrsoho.top/en/copilot/quickstart#introduction"
                variant="accent"
                {...analyticsEvent({
                  action: 'quickstart_docs',
                  tag: 'link',
                  context: 'ai_suggestions',
                  location: 'features',
                })}
              >
                Read the docs
              </Link>
            </River.Content>
          </River>
        </Grid.Column>

        {/* Spacer */}
        <Box paddingBlockStart={48} aria-hidden />

        <Grid.Column span={12}>
          <Bento className="Bento Bento--raised">
            <Bento.Item columnSpan={12} colorMode="dark" style={{gridRowEnd: 'auto'}}>
              <Bento.Content
                padding={{
                  xsmall: 'normal',
                  medium: 'spacious',
                }}
                horizontalAlign="center"
              >
                <Bento.Heading
                  as="h3"
                  size="3"
                  weight="semibold"
                  className="text-center mt-2 mt-md-0"
                  style={{maxWidth: '660px'}}
                >
                  Ask for assistance right in your terminal
                </Bento.Heading>

                <Link
                  href="https://docs-gh.yrsoho.top/copilot/using-github-copilot/using-github-copilot-in-the-command-line"
                  size="large"
                  variant="default"
                  {...analyticsEvent({action: 'cli_docs', tag: 'link', context: 'terminal', location: 'features'})}
                >
                  Try Copilot in the CLI
                </Link>
              </Bento.Content>

              <Bento.Visual fillMedia={false} className="lp-Features-terminal-visual">
                <Image
                  src={CopilotTerminalLgImage}
                  alt="Screenshot of GitHub Copilot CLI in a terminal"
                  width="831"
                  height="369"
                  className="lp-Features-terminal-visual-img d-none d-md-block mx-auto"
                />

                <Image
                  src={CopilotTerminalSmImage}
                  alt="Screenshot of GitHub Copilot CLI in a terminal"
                  width="557"
                  height="371"
                  className="lp-Features-terminal-visual-img d-block d-md-none ml-auto"
                />
              </Bento.Visual>
            </Bento.Item>

            <Bento.Item
              columnSpan={{xsmall: 12, medium: 5, large: 5, xlarge: 5}}
              rowSpan={{xsmall: 4, small: 4, medium: 4, xlarge: 5}}
              className="Bento-item"
            >
              <Bento.Content
                horizontalAlign={{xsmall: 'center', large: 'start'}}
                padding={{xsmall: 'normal', xlarge: 'spacious'}}
                leadingVisual={<DeviceMobileIcon />}
                className="lp-Features-mobile"
              >
                <Bento.Heading as="h3" size="4" weight="semibold" className="lp-Features-mobileText">
                  Chat with your AI pair programmer on-the-go
                </Bento.Heading>
              </Bento.Content>
              <Bento.Visual
                padding={{xsmall: 'normal', xlarge: 'spacious'}}
                fillMedia={false}
                horizontalAlign="center"
                verticalAlign="end"
                style={{columnGap: '24px', flexWrap: 'wrap'}}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.github.android"
                  {...analyticsEvent({
                    action: 'play_store',
                    tag: 'button',
                    context: 'mobile_apps',
                    location: 'features',
                  })}
                >
                  <Image
                    src={GooglePlayImage}
                    alt="Google Play Store logo"
                    width="180"
                    height="53"
                    style={{display: 'block'}}
                  />
                </a>
                <a
                  href="https://apps.apple.com/app/github/id1477376905?ls=1"
                  {...analyticsEvent({
                    action: 'app_store',
                    tag: 'button',
                    context: 'mobile_apps',
                    location: 'features',
                  })}
                >
                  <Image
                    src={AppStoreImage}
                    alt="Apple App Store logo"
                    width="179"
                    height="53"
                    style={{display: 'block'}}
                  />
                </a>
              </Bento.Visual>
            </Bento.Item>

            <Bento.Item
              columnSpan={{xsmall: 12, medium: 7, large: 7, xlarge: 7}}
              rowSpan={{xsmall: 4, small: 3, medium: 4, xlarge: 5}}
            >
              <Bento.Visual position="25% 0%">
                <Image
                  src={CopilotMobileImage}
                  alt="A phone showing GitHub Copilot in GitHub Mobile"
                  width="724"
                  height="560"
                />
              </Bento.Visual>
            </Bento.Item>
          </Bento>
        </Grid.Column>
      </Grid>
    </section>
  )
}

try{ AutoPlayVideo.displayName ||= 'AutoPlayVideo' } catch {}
try{ FeaturesSection.displayName ||= 'FeaturesSection' } catch {}